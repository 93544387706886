import * as React from 'react';
import {
  VStack,
  Text,
  Heading,
  ListItem,
  OrderedList,
  Flex,
} from '@chakra-ui/react';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { useMixpanel } from 'gatsby-plugin-mixpanel';

const Terms: React.FC = () => {
  const mixpanel = useMixpanel();
  React.useEffect(() => {
    mixpanel.track('Homepage: Viewed Terms and Conditions');
  }, [mixpanel]);

  return (
    <Layout>
      <SEO title="Terms and Conditions" />
      <Flex direction="column" align="center" justify="center">
        <Heading px={10} pt={10} textAlign="center">
          Canyou Terms and Conditions
        </Heading>
        <VStack p={10} mx={5} spacing={4} maxWidth="900px">
          <Text>
          Welcome to Canyou! We provide software that allows you to automate
          your business processes and build customised workflows for your
          business. These terms and conditions (Terms) set out the terms on
          which we will provide these services to you. Disclosures: Please read
          these Terms carefully prior to accepting them. By accepting these
          Terms, you agree that: * where we provide a direct debit facility, you
          will be debited the Fees monthly until you terminate your Account; *
          subject to your conusumer rights, our Services are provided “as is”
          without any guarantees or warranties; * we may amend the Services or
          the Fees at any time with notice. If you do not agree to the change,
          you may terminate the terms without penalty; * you must pay us our
          losses caused by materials you provide to us, your breach of
          confidentiality, privacy, intellectual property rights, and due to
          your negligence or wilful misconduct; and * subject to your consumer
          rights, we exclude and limit our liability as set out in clauses 17
          and 18 (for example we won’t be liable for consequential or indirect
          losses or loss of profits, revenue, opportunity, data and our
          liability will not exceed the Fees paid by you in the past 12 months).
          </Text>
          <OrderedList>
            <ListItem>
              Acceptance 1.1 API Software Pty Ltd t/a Canyou Software(ABN 57 650
              764 195) (we, us or our), has the rights to the cloud-based software, including
              all instructions in hard copy or electronic form and any update,
              modification or release of any part of that software (Software)
              which is available through our website (Site). 1.2 These terms and
              conditions (Terms): (a) set out the terms and conditions upon
              which we agree to grant you a right to use the Services; and (b)
              are binding on you on and from the date on which you accept these
              Terms (Effective Date) until the date on which your Account and
              these Terms are terminated in accordance with the terms (the
              Term). 1.3 You accept these Terms by clicking a box indicating
              your acceptance. 1.4 If you create an Account and are agreeing to
              these Terms on behalf of a company, your employer, an
              organisation, government or other legal entity (Entity), then
              “you” means the Entity and you are binding that Entity to these
              Terms. If you are accepting these Terms on behalf of an Entity,
              you as an individual represent and warrant that you are authorised
              by the Entity to do so.
            </ListItem>
            <ListItem>
              Services 2.1 In consideration of payment of the Fees, we will
              provide the Services to you and your Authorised Users in
              accordance with these Terms, whether ourselves or through our
              Personnel. 2.2 You agree that we may amend the Services (including
              any features) or the Fees at any time, by providing written notice
              to you. If you do not agree to any amendment made to the Services
              or the Fees, you may terminate these Terms in accordance with
              clause 19.1. 2.3 We warrant and agree that, we will use reasonable
              effort to ensure all of our obligations under these Terms will be
              carried out: (a) by suitably competent and trained Personnel; and
              (b) in an efficient and professional manner.
            </ListItem>
            <ListItem>
              Licence and licence restrictions 3.1 Subject to your compliance
              with these Terms, we grant you (and your Authorised Users) a
              non-exclusive, non-transferable (except with our written
              permission), non-sublicensable (except as otherwise permitted
              under these Terms), personal and revocable licence to access and
              use the Services for your use and enjoyment as contemplated by
              these Terms, for the Term (Licence). 3.2 You must not (and must
              ensure that any Authorised Users do not) access or use the
              Services except as permitted by the Licence and you must not and
              must ensure that any other person (including an Authorised User)
              does not: (a) use the Services in any way that breaches any
              applicable Laws or infringes any person&apos;s rights, including
              Intellectual Property Rights (and privacy rights); (b) use the
              Services in any way that damages, interferes with or interrupts
              the supply of the Services; (c) introduce malicious programs into
              our hardware and software or Systems, including any viruses or
              malware through any channel; (d) allow others to access or use
              your/their Account, password or authentication details; (e)
              continue to use the Services after termination of their employment
              or relationship with you; (f) use the Services to carry out
              security breaches or disruptions of a network. This includes
              accessing data where you are not the intended recipient or logging
              into a server or account that you are not expressly authorised to
              access or corrupting any data (including network
              sniffing/monitoring, pinged floods, packet spoofing, denial of
              service and forged routing information for malicious purposes);
              (g) use any program/script/command, or send messages of any kind,
              with the intent to interfere with, or disable, any person&apos;s
              use of the Services; (h) use the Services to circumvent user
              authentication or security of any of our networks, accounts or
              hosts or those of any other users; (i) use the Services to
              transmit, publish or communicate material that is defamatory,
              offensive, abusive, indecent, menacing or unwanted; and (j) if
              applicable, send any form of harassment via email, or any other
              form of messaging, whether through language, frequency, or size of
              messages, or use the Services in breach of any person&apos;s
              privacy (such as by way of identity theft or
              &quot;phishing&quot;).
            </ListItem>
            <ListItem>
              Account and logins 4.1 You must create an Account on our Site in
              order to use the Services. When you do this, you become an Admin
              User and you are responsible for your Account and any Authorised
              Users. As an Admin User, you can manage the access and permissions
              of other Authorised Users. If you grant any other user
              administration rights, then they will also become an Admin User.
              Admin Users will be able to manage the Account, modify the access
              privileges of Authorised Users and invite and remove Authorised
              Users. All Admin Users will be deemed to be an authorised
              representative of the Entity for the purposes of making any
              decisions about the Account and those decisions will be deemed
              binding on the Entity. 4.2 You may invite your Personnel to access
              the Services as Authorised Users. Authorised Users should each
              have their own login but they are part of your Account and they
              are your responsibility. 4.3 You agree that we will require every
              Authorised User to enter into an end user licence agreement (EULA)
              with us in the form we provide to you at
              https://canyou.com.au/end-user-licence-agreement. 4.4 You agree
              that the Licence permits you and your Authorised Users to access
              and use the Services. 4.5 You must ensure that any information you
              provide to us, or we request from you, for any Account or login,
              is complete and accurate, and you are authorised to provide this
              information to us. 4.6 It is your responsibility to keep your
              Account details confidential and to ensure that all Authorised
              Users do the same in relation to their login details. You are
              responsible for all activity on your Account and all activity by
              any Authorised User, and for ensuring that any activities on any
              Account and login complies with these Terms. 4.7 We are not
              responsible for the management or administration of your Account,
              any logins or your Authorised Users.
            </ListItem>
            <ListItem>
              Access and availability 5.1 We agree to use commercially
              reasonable endeavours to ensure that the Services will be
              available and accessible at all times during the Term. 5.2 During
              the Term, from time to time, we may perform scheduled and
              emergency maintenance and updates in relation to the Services. You
              agree that access to, or the functionality of all or part of the
              Services, may need to be suspended for a time in order for us to
              do this. 5.3 We will endeavour to provide you with reasonable
              notice, where possible, of any interruptions to access and
              availability of the Services.
            </ListItem>
            <ListItem>
              Third Party Inputs 6.1 You agree that the Services may include
              Third Party Inputs that interface, or interoperate, with the
              Services, including third party software or services and that the
              provision of the Services may be contingent on, limited to or
              impacted by, Third Party Inputs. 6.2 To the extent that you choose
              to use such Third Party Inputs, you are responsible for: (a) the
              requirements; and (b) the licensing obligations, related to the
              Third Party Input. 6.3 As part of the Services, we may provide the
              option for you and your Authorised Users to carry out various
              checks, lodgements, and procedures with external Third Party
              Inputs (Procedures). You understand and agree that: (a) your and
              your Authorised Users’ carrying out of such Procedures: (1)
              requires the collection, transmission and disclosure of User Data
              (which includes Personal Information and may include identity
              documentation) to Third Party Inputs; (2) relies on Third Party
              Inputs; and (3) relies on the accuracy of the User Data and the
              steps taken by you and your Authorised Users to complete such
              Procedures correctly, and you should perform your own due
              diligence to ensure that such Procedures are submitted and/or
              completed correctly; and (b) we may refuse to allow you to carry
              out Procedures if we reasonably believe that doing so is
              fraudulent, invalid, suspicious, or breaches any Law or these
              Terms. We have no Liability to you or your Authorised Users where
              we notify you of a refusal in accordance with this clause 6.3(b).
              6.4 You must comply with our instructions and directions, whether
              written or verbal, in relation to use of any Third-Party Inputs.
              6.5 You agree that the benefit of any Third Party Input’s
              interface, or interoperation with, the Services, is subject to
              your compliance with clause 6.4 and that we have no Liability to
              you (including for any loss of access to User Data or corruption
              of User Data) if any Third Party Input withdraws your access to
              their services or withdraws their services from integration with
              our Services.
            </ListItem>
            <ListItem>
              Support Services 7.1 During the Term, we will provide you
              technical support services on Business Days between 9am-5pm,
              online or via email, provided that where required, you assist us
              in investigating and ascertaining the cause of the fault and
              provide us with access to all necessary information relevant to
              the fault (including what you have done in relation to the fault).
              We will aim to respond to your enquiries within 48 business hours.
              7.2 The support in clause 7.1 does not apply to Third Party
              Inputs.
            </ListItem>
            <ListItem>
              Your obligations 8.1 You warrant, represent and agree: (a) you
              have the legal capacity to enter into a legally binding agreement;
              (b) there are no legal restrictions preventing you from agreeing
              to these Terms; (c) that you have reviewed and understand these
              Terms (including our Privacy Policy) and the EULA, and will use
              the Services in accordance with them, our reasonable requests or
              requirements, and all applicable Laws; (d) you will be responsible
              for the use of any part of the Services, and you must ensure that
              no person uses any part of the Services to break any Law or
              infringe any person’s rights (including Intellectual Property
              Rights and privacy rights) or in any way that damages, interferes
              with or interrupts the supply of the Services; (e) to ensure that
              all workflows you create using the Services comply with all
              applicable Laws (including the Privacy Act and any applicable
              anti-discrimination and equal opportunity legislation); (f) to
              cooperate with us and provide all assistance, resources, data,
              people, information, facilities, access and documentation
              reasonably necessary to enable us to comply with our obligations
              under these Terms or at Law, in a timely manner; (g) all
              information and documentation that you provide to us in connection
              with these Terms is true, correct and complete and that we will
              rely on such information and documentation in order to provide the
              Services; (h) you have not relied on any representations or
              warranties made by us in relation to the Services (including as to
              whether the Services are or will be fit or suitable for your
              particular purposes) or any Third Party Inputs, unless expressly
              stipulated in these Terms; (i) you have not made any
              representations or warranties to any third parties that could be
              construed as being representations or warranties from us in
              relation to the Services or any other matter; (j) you are
              responsible for all users using the Services, including your
              Personnel and any Authorised Users; (k) the Services and any
              associated programs and files are used at your own risk; (l) the
              technical processing and transmission of the Services, including
              User Data, may be transferred unencrypted and involves: (1)
              transmissions over various networks; and (2) changes to conform
              and adapt to technical requirements of connecting networks or
              devices; (m) you are responsible for obtaining, and providing to
              us in a timely manner, any consents, licences, authorities and
              permissions from third parties necessary for the Services to be
              provided in accordance with these Terms, at your cost; (n) we are
              not responsible for the integrity or existence of any data on the
              Computing Environment, network or any device controlled by you,
              your Authorised Users or your Personnel; and (o) the Services are
              provided to you solely for your benefit and you will not (or you
              will not attempt to) disclose, or provide access to, our Services
              to third parties without our prior written consent; (p) you have
              the authority to act on behalf of any person or entity for whom
              you are using the Services and you are deemed to have agreed to
              these Terms on behalf of any entity for whom you use the Services;
              and (q) you have all hardware, software and services which are
              necessary to access and use the Services (other than those
              required to be provided by us under these Terms). 8.2 To the
              extent contemplated, this clause 8 will survive termination or
              expiry of these Terms.
            </ListItem>
            <ListItem>
              Fees and payment 9.1 Invoices Until we provide a facility to pay
              via our payment processor, you are required to pay the Fees via
              invoice, and you agree to pay the Fees in accordance with the
              payment terms set out in the invoice. 9.2 Direct Debit 9.3 Once we
              have a payment processor, you agree to pay us the Fees, and any
              other amounts payable to us under these Terms, without set-off or
              delay, via direct debit through our payment processor. 9.4 If
              required, you also agree to complete a direct debit request form
              and/or direct debit request service agreement, which may be
              provided by us or Stripe. General 9.5 You must pay the Fee on the
              1st day of each month in advance for the month. However on the
              first payment date, you will also pay the Fee calculated on a
              pro-rata basis for the number of days between the Effective Date
              and the first payment date. For example, if the Effective Date is
              28th January, on 1 February, you will pay the Fee for February and
              the pro-rata fee for the days from 28th January to 31st January.
              9.6 You are responsible for reviewing the pricing schedule,
              features and inclusions of the Services, which are available on
              the Site. 9.7 You must ensure your chosen payment method has
              sufficient funds to pay the Fees. 9.8 If any payment has not been
              made or is not successful in accordance with these Terms, we may
              (at our absolute discretion): (a) immediately cease or suspend the
              provision of the Services, and recover as a debt due and
              immediately payable from you, our additional costs of doing so
              including any dishonour fee charged by our payment processor; (b)
              charge interest at a rate equal to the Reserve Bank of Australia’s
              cash rate from time to time plus 2% per annum, calculated daily
              and compounding monthly, on any such amounts unpaid after the due
              date for payment in accordance with these Terms; and (c) engage
              debt collection services and/or commence legal proceedings in
              relation to any such amounts. 9.9 If you rectify such non-payment
              within a reasonable time after the Services have been suspended,
              then we may, at our discretion, recommence the provision of the
              Services as soon as reasonably practicable.
            </ListItem>
            <ListItem>
              Communication and privacy 10.1 You agree that we may contact you
              via the platform for the Services, using in-account notifications
              or via-off platform communication channels, such as text messages
              or email with functional notifications. 10.2 You further agree
              that we may also send marketing and promotional material which may
              be of interest to you, using your contact details. You may opt out
              of receiving direct marketing messages at any time. Where you
              opt-out we will continue to send you functional communications
              relevant to your use of the Services. 10.3 You are responsible for
              the collection, use, storage and otherwise dealing with Personal
              Information related to your business and all matters relating to
              the User Data. 10.4 You must, and must ensure that your Personnel
              and Authorised Users, comply with the legal requirements of the
              Australian Privacy Principles as set out in the Privacy Act 1988
              (Cth) and any privacy or anti-spam Laws applicable to you in
              respect of all Personal Information collected, used, stored or
              otherwise dealt with under or in connection with these Terms
              (Privacy Laws). 10.5 Without limiting this clause 10, you must
              only disclose Personal Information, if: (a) you are authorised by
              Privacy Laws to collect the Personal Information and to use or
              disclose it in the manner required by these Terms; (b) you have
              informed the individual to whom the Personal Information relates,
              that it might be necessary to disclose the Personal Information to
              third parties; and (c) where any Personal Information is Sensitive
              Information, you have obtained the specific consent to that
              disclosure from the individual to whom the Sensitive Information
              relates. 10.6 We agree to handle any Personal Information provided
              to us, solely for the purpose of performing our obligations under
              these Terms, and in accordance with any applicable Laws and our
              Privacy Policy.
            </ListItem>
            <ListItem>
              Confidential Information 11.1 Each Receiving Party agrees: (a) not
              to disclose the Confidential Information of the Disclosing Party
              to any third party; (b) to use all reasonable endeavours to
              protect the Confidential Information of the Disclosing Party from
              any unauthorised disclosure; and (c) to only use the Confidential
              Information of the Disclosing Party for the purposes for which it
              was disclosed or provided by the Disclosing Party, and not for any
              other purpose. 11.2 The obligations in clause 11.1 do not apply to
              Confidential Information that: (a) is required to be disclosed in
              order for the Parties to comply with their obligations under these
              Terms; (b) is authorised to be disclosed by the Disclosing Party;
              (c) is in the public domain and/or is no longer confidential,
              except as a result of a breach of these Terms; or (d) must be
              disclosed by Law or by a regulatory authority, including under
              subpoena. 11.3 Each Party agrees that monetary damages may not be
              an adequate remedy for a breach of this clause 11. A Party is
              entitled to seek an injunction, or any other remedy available at
              law or in equity, at its discretion, to protect itself from a
              breach (or continuing breach) of this clause 11. 11.4 This clause
              11 will survive the termination or expiry of these Terms.
            </ListItem>
            <ListItem>
              Australian Consumer Law 12.1 Certain legislation, including the
              ACL, and similar consumer protection laws and regulations may
              confer you with rights, warranties, guarantees and remedies
              relating to the provision of our services which cannot be
              excluded, restricted or modified (Statutory Rights). 12.2 If the
              ACL applies to you as a consumer, nothing in these Terms excludes
              your Statutory Rights as a consumer under the ACL. You agree that
              our Liability for the Services provided to an entity defined as a
              consumer under the ACL is governed solely by the ACL and these
              Terms. 12.3 Subject to your Statutory Rights, we exclude all
              express and implied warranties, and all material, work and
              services (including the Services) are provided to you without
              warranties of any kind (including as to fitness for purpose),
              either express or implied, whether in statute, at Law or any other
              basis. 12.4 This clause 12 will survive termination or expiry of
              these Terms.
            </ListItem>
            <ListItem>
              Intellectual Property Rights 13.1 This clause 13 will survive
              termination or expiry of these Terms. 13.2 The Parties agree that
              nothing in these Terms constitutes a transfer or assignment of any
              Intellectual Property Rights. 13.3 You agree that we (or the
              relevant third party) own all Intellectual Property Rights in: (a)
              Our Materials; (b) New Materials or Improvements; (c) any
              Feedback, and these Intellectual Property Rights will at all times
              vest, or remain vested, in us (or, if applicable, our third-party
              service providers). To the extent that ownership of these
              Intellectual Property Rights does not automatically vest in us,
              you agree to do all acts necessary or desirable to assure our
              title to such rights. 13.4 In the use of any Intellectual Property
              Rights in connection with these Terms, you agree that you must not
              (and you must ensure that your Personnel do not) commit any
              Intellectual Property Breach. Where you reasonably suspect that
              such a breach may have occurred, you must notify us immediately.
              13.5 You also agree that: (a) we may use Feedback in any manner
              which we see fit (including to develop new features) and no
              benefit will be due to you as a result of any use by us of any
              Feedback; (b) you must not whether directly or indirectly, without
              our prior written consent: (1) copy, modify, adapt, translate,
              create a derivative work of, reverse engineer, reverse assemble,
              disassemble or decompile the Services (or any part of the
              Services) or otherwise attempt to discover any part of the source
              code of the Services; (2) use any unauthorised, modified version
              of the Services, including (without limitation) for the purpose of
              building similar or competitive software or for the purpose of
              obtaining unauthorised access to the Services; (3) unless
              authorised under these Terms, use the Services in a web-enabled
              form for the purposes of third-party analysis or view via the
              internet or other external network access method; (4) rent the use
              of the Services to any third parties; (5) take any action that may
              compromise or jeopardise our Intellectual Property Rights in the
              Services or otherwise; (6) remove or deface any confidentiality,
              copyright or other proprietary notice placed on the Services; or
              (7) use the Services in any way that involves service bureau use,
              outsourcing, renting, reselling, sublicensing, concurrent use of a
              single user login, or time-sharing.
            </ListItem>
            <ListItem>
              Analytics 14.1 Despite anything to the contrary, we may monitor,
              analyse and compile statistical and performance information based
              on and/or related to your use of the Services and the User Data,
              in an aggregated and anonymised format (Analytics). You agree that
              we may make such Analytics publicly available, provided that it:
              (a) does not contain identifying information; and (b) is not
              compiled using a sample size small enough to make the underlying
              data identifiable. 14.2 We, and our licensors own all right, title
              and interest in and to the Analytics and all related software,
              technology, documentation and content used or provided in
              connection with the Analytics, including all Intellectual Property
              Rights in the foregoing. 14.3 We may also share your Account
              username and email addresses with third party quality assurance
              providers, Mixpanel and Sentry.
            </ListItem>
            <ListItem>
              User Data 15.1 You grant us a limited licence to copy, transmit,
              store and back-up or otherwise access the User Data during the
              Term and for a reasonable period after the Term to: (a) supply the
              Services (including for back-ups) to you (including to enable you,
              your Authorised Users and your Personnel to benefit from the
              Services); (b) diagnose problems with the Services; (c) develop
              other services, provided we de-identify the User Data; (d) enhance
              and otherwise modify the Services; and (e) as reasonably required
              to perform our obligations under these Terms. 15.2 You must, at
              all times, ensure the integrity of the User Data and that your
              provision of, and use of the User Data is compliant with all Laws.
              15.3 You represent and warrant that: (a) you have obtained all
              necessary rights, releases and permissions to provide all your
              User Data to us and to grant the rights granted to us in these
              Terms; (b) the User Data is accurate and complete; (c) the User
              Data (and its transfer to and use by us as authorised by you)
              under these Terms does not violate any Laws (including those
              relating to export control and electronic communications) or
              rights of any third party, including any Intellectual Property
              Rights, rights of privacy, or rights of publicity; and (d) any
              use, collection and disclosure authorised in these Terms is not
              inconsistent with the terms of any applicable privacy policies.
              15.4 We assume no responsibility or Liability for the User Data.
              You are solely responsible for the User Data and the consequences
              of using, disclosing, storing or transmitting it. It is your
              responsibility to back up the User Data. 15.5 You acknowledge and
              agree that the operation of the Services is reliant on the
              accuracy of the User Data, and the provision of inaccurate or
              incomplete User Data by you may affect the use, output and
              operation of the Services.
            </ListItem>
            <ListItem>
              Indemnities 16.1 Except to the extent that the Liability arose
              from our negligent acts or omissions or any of our Personnel, you
              indemnify us and our Personnel against all Liability that we or
              any of our Personnel may sustain or incur as a result, whether
              directly or indirectly, of: (a) your or your Personnel’s breach of
              clauses 6.4, 9, 10 and 11; (b) your or your Personnel’s negligent,
              unlawful or wilful acts or omissions in connection with these
              Terms; (c) the incorrect or incomplete carrying out of any
              Procedures as a result of your use of the Services; (d) a breach
              by you or your Personnel of any Laws which occurs as a consequence
              of your use of the Services.
            </ListItem>
            <ListItem>
              Limitations on Liability 17.1 Despite anything to the contrary
              (except for clause 15) and to the maximum extent permitted by law:
              (a) neither Party will be liable for any Consequential Loss; (b) a
              Party’s liability for any Liability under these Terms will be
              reduced proportionately to the extent the relevant Liability was
              caused or contributed to by the acts or omissions of the other
              Party (or any of its Personnel); and (c) our aggregate liability
              for any Liability arising from or in connection with these Terms
              will be limited to us repaying you the amount of the Fees paid by
              you to us in the immediately preceding 12 months for the supply of
              the relevant Services to which the Liability relates. 17.2 This
              clause 17 will survive termination or expiry of these Terms.
            </ListItem>
            <ListItem>
              Exclusions to Liability 18.1 Despite anything to the contrary, to
              the maximum extent permitted by law, we will not be liable for,
              and you waive and release us from and against, any Liability,
              caused or contributed to by, arising from or connected with: (a)
              the inaccuracy of User Data as submitted by an Authorised User.
              You acknowledge and agree that the operation of the Services is
              reliant on the accuracy of the User Data, and the provision of
              inaccurate or incomplete User Data by you or your Authorised Users
              may affect the use, output and operation of the Services; (b) loss
              of, or damage to, any property or any injury to or loss to any
              person; (c) the Computing Environment; (d) your or your
              Personnel’s negligent, unlawful or fraudulent acts or omissions;
              (e) any claim by an Authorised User; (f) any use or application of
              the Services by a person or entity other than you, or other than
              as reasonably contemplated by these Terms; (g) any event outside
              of our reasonable control (including a Force Majeure Event, and a
              fault, defect, error or omission in the Computing Environment or
              User Data). (h) any work, services, goods, materials or items
              which do not form part of the Services (as expressed in these
              Terms), or which have not been provided by us; and (i) any Third
              Party Inputs. 18.2 This clause 18 will survive termination or
              expiry of these Terms.
            </ListItem>
            <ListItem>
              Termination 19.1 We may terminate an Account and these Terms may
              be terminated with 30 days’ written notice. 19.2 You may terminate
              your Account and these Terms at any time before the next payment
              date. Where this termination right is exercised, these Terms, your
              Account and all Authorised User logins will terminate on the next
              payment date. 19.3 These Terms, your Account and all Authorised
              User logins will terminate immediately upon written notice by a
              Party (Non-Defaulting Party) if the other Party (Defaulting Party)
              breaches a material term of these Terms and that breach has not
              been remedied within 10 Business Day of the Defaulting Party being
              notified of the Breach by the Non-Defaulting Party. 19.4 Upon
              expiry or termination of these Terms: (a) we will immediately
              cease providing the Services; (b) you are to pay all other amounts
              due and payable under these Terms; (c) to the maximum extent
              permitted by law, Fees paid are not refundable and there will be
              not refunds or credits for any unused Licence (or part thereof);
              (d) pursuant to clause 19.3, you also agree to pay us additional
              costs arising from, or in connection with, such termination; and
              (e) you must immediately return (where possible) or delete or
              destroy (where not possible to return), any of our property
              (including any of our Confidential Information and Intellectual
              Property). 19.5 Termination of these Terms will not affect any
              rights or liabilities that a party has accrued under it. 19.6 This
              clause 19 will survive the termination or expiry of these Terms.
            </ListItem>
            <ListItem>
              GST 20.1 Unless specified otherwise, all amounts in these Terms
              are exclusive of GST. 20.2 If GST is payable on any supply made
              under these Terms, the recipient of the supply must pay an amount
              equal to the GST payable on the supply. That amount must be paid
              at the same time that the consideration is to be provided under
              these Terms and must be paid in addition to the consideration
              expressed elsewhere in these Terms, unless it is expressed to be
              inclusive of GST. The recipient is not required to pay any GST
              until the supplier issues a tax invoice for the supply. 20.3 If an
              adjustment event arises in respect of any supply made under these
              Terms, a corresponding adjustment must be made between the
              supplier and the recipient in respect of any amount paid by the
              recipient under this clause, an adjustment note issued (if
              required), and any payments to give effect to the adjustment must
              be made. 20.4 If the recipient is required under these Terms to
              pay for or reimburse an expense or outgoing of the supplier, or is
              required to make a payment under an indemnity in respect of an
              expense or outgoing of the supplier, the amount to be paid by the
              recipient is to be reduced by the amount of any input tax credit
              in respect of that expense or outgoing that the supplier is
              entitled to. 20.5 The terms “adjustment event”, “consideration”,
              “GST”, “input tax credit”, “recipient”, “supplier”, “supply”,
              “taxable supply” and “tax invoice” each has the meaning which it
              is given in the A New Tax System (Goods and Services Tax) Act 1999
              (Cth).
            </ListItem>
            <ListItem>
              Disputes 21.1 A party may not commence court proceedings relating
              to any dispute, controversy or claim arising from, or in
              connection with, these Terms (including any question regarding its
              existence, validity or termination) (Dispute) without first
              meeting with a senior representative of the other party to seek
              (in good faith) to resolve the Dispute. 21.2 If the parties cannot
              agree how to resolve the Dispute at that initial meeting, either
              party may refer the matter to a mediator. If the parties cannot
              agree on who the mediator should be, either party may ask the Law
              Society of New South Wales to appoint a mediator. The mediator
              will decide the time, place and rules for mediation. The parties
              agree to attend the mediation in good faith, to seek to resolve
              the Dispute. The costs of the mediation will be shared equally
              between the parties. 21.3 If the parties cannot agree how to
              resolve the Dispute at mediation, either party may refer the
              Dispute to arbitration in accordance with the Australian Centre
              for International Commercial Arbitration (ACICA) rules. Once a
              Dispute has been referred to the ACICA, the parties agree to be
              bound by the decision of the ACICA. The seat of arbitration shall
              be Sydney, Australia. The language of the arbitration shall be
              English. The number of arbitrators shall be one. 21.4 Nothing in
              this clause will operate to prevent a party from seeking urgent
              injunctive or equitable relief from a court of appropriate
              jurisdiction. 21.5 This clause 21 will survive the termination or
              expiry of these Terms.
            </ListItem>
            <ListItem>
              General 22.1 Amendment: We may update these Terms at any time.
              Where we update these Terms, we will notify you via an in-Account
              notification or via email. If you do not agree with any amendment
              you may terminate these Terms in accordance with clause 19.2. 22.2
              Assignment: A party must not assign or deal with the whole or any
              of its rights or obligations under these Terms without the prior
              written consent of the other Party (such consent is not to be
              unreasonably withheld). 22.3 Entire agreement: These Terms contain
              the entire understanding between the parties, and supersedes all
              previous discussions, communications, negotiations,
              understandings, representations, warranties, commitments and
              agreements, in respect of its subject matter. 22.4 Force Majeure:
              We will not be liable for any delay or failure to perform our
              obligations under these Terms if such delay is due to any
              circumstance beyond our reasonable control (including but not
              limited to epidemics, pandemics, and Government sanctioned
              restrictions and orders, whether known or unknown at the time of
              entering into these Terms) (Force Majeure Event). 22.5 Further
              assurance: Each party must promptly do all things and execute all
              further instruments necessary to give full force and effect to
              these Terms and their obligations under it. 22.6 Governing law:
              These Terms are governed by the laws of New South Wales. Subject
              to clause 21, each party irrevocably and unconditionally submits
              to the exclusive jurisdiction of the courts operating in New South
              Wales and any courts entitled to hear appeals from those courts
              and waives any right to object to proceedings being brought in
              those courts. 22.7 Notices: Any notice given under these Terms
              must be in writing addressed to the relevant address last notified
              by the recipient to the parties. Any notice may be sent by
              standard post or email, and will be deemed to have been served on
              the expiry of 3 Business Days in the case of post, or at the time
              of transmission in the case of transmission by email (or, where
              the time of transmission is not on a Business Day, 9am on the next
              Business Day). 22.8 Publicity: You agree that we may advertise or
              publicise the broad nature of our provision of the Services to
              you, including on our website or in our promotional material. We
              may seek your prior written consent to use your trade mark and
              logo for promotional purspoes, and where you provide such
              consent,you grant us a licence to use your trade mark and logo for
              promotional purposes. 22.9 Relationship of Parties: These Terms
              are not intended to create a partnership, joint venture,
              employment or agency relationship between the Parties. 22.10
              Severance: If a provision of these Terms is held to be void,
              invalid, illegal or unenforceable, that provision is to be read
              down as narrowly as necessary to allow it to be valid or
              enforceable, failing which, that provision (or that part of that
              provision) will be severed from these Terms without affecting the
              validity or enforceability of the remainder of that provision or
              the other provisions.
            </ListItem>
            <ListItem>
              Definitions In these Terms, unless the context otherwise requires,
              capitalised terms have the meanings given to them in these Terms,
              and: Account means an account accessible to you and/or your
              Authorised Users to use the Services; ACL or Australian Consumer
              Law means the Australian consumer laws set out in Schedule 2 of
              the Competition and Consumer Act 2010 (Cth), as amended, from time
              to time; Admin User is defined in clause 4.1; Authorised User
              means a user permitted to access and use the Services under your
              Account, whether one of your employees, clients, members or
              similar; Business Day means a day on which banks are open for
              general bank business in New South Wales, excluding Saturdays,
              Sundays and public holidays; Computing Environment means your
              computing environment including all hardware, software,
              information technology and telecommunications services and
              Systems; Confidential Information includes information which: (a)
              is disclosed to the Receiving Party in connection with these Terms
              at any time; (b) is prepared or produced under or in connection
              with these Terms at any time; (c) relates to the Disclosing
              Party’s business, assets or affairs; or (d) relates to the subject
              matter of, the terms of and/or any transactions contemplated by
              these Terms, whether or not such information or documentation is
              reduced to a tangible form or marked in writing as “confidential”,
              and howsoever the Receiving Party receives that information;
              Consequential Loss includes any consequential loss, indirect loss,
              real or anticipated loss of profit, loss of benefit, loss of
              revenue, loss of business, loss of goodwill, loss of opportunity,
              loss of savings, loss of reputation, loss of use and/or loss or
              corruption of data, whether under statute, contract, equity, tort
              (including negligence), indemnity or otherwise; Disclosing Party
              means the party disclosing Confidential Information to the
              Receiving Party; Fee or Fees means those fees due and payable by
              you for the Services, as set out on the Site; Feedback means any
              idea, suggestion, recommendation or request by you or any of your
              Personnel or Authorised Users, whether made verbally, in writing,
              directly or indirectly, in connection with the Services;
              Intellectual Property means any copyright, registered and
              unregistered trade marks, designs (whether or not registered or
              registrable), domain names, know-how, inventions, processes, trade
              secrets or Confidential Information; or circuit layouts, software,
              computer programs, databases or source codes, including any
              application, or right to apply, for registration of, and any
              improvements, enhancements or modifications of, the foregoing;
              Intellectual Property Breach means any breach by you (or any of
              your Personnel) of any of our Intellectual Property Rights (or any
              breaches of third-party rights including any Intellectual Property
              Rights of third parties), including using or exploiting our
              Intellectual Property for purposes other than as expressly stated
              in these Terms (including, without limitation, using our
              Intellectual Property for commercial purposes or on-selling our
              Intellectual Property to third parties); Intellectual Property
              Rights means for the duration of the rights in any part of the
              world, any industrial or intellectual property rights, whether
              registrable or not, including in respect of Intellectual Property;
              Laws means all applicable laws, regulations, codes, guidelines,
              policies, protocols, consents, approvals, permits and licences,
              and any requirements or directions given by any person with the
              authority to bind the relevant Party in connection with these
              Terms or the provision of the Services, and includes the Privacy
              Act 1988 (Cth) and the Spam Act 2003 (Cth); Liability means any
              expense, cost, liability, loss, damage, claim, notice,
              entitlement, investigation, demand, proceeding or judgment
              (whether under statute, contract, equity, tort (including
              negligence), indemnity or otherwise), howsoever arising, whether
              direct or indirect and/or whether present, unascertained, future
              or contingent and whether involving a third party or a Party to
              these Terms or otherwise; Personal Information is defined in the
              Privacy Act 1988 (Cth) and also includes any similar term as
              defined in any other privacy law applicable to you; Personnel
              means, in respect of a party, any of its employees, clients,
              members, consultants, suppliers, subcontractors or agents; Privacy
              Policy means any privacy policy set out on our Site; Procedures is
              defined in clause 6.3; Receiving Party means the party receiving
              Confidential Information from the Disclosing Party; Sensitive
              Information is defined in the Privacy Laws; Services means the
              Software, and the Software as service provided through the Site;
              System means all hardware, software, networks and other IT systems
              used by a Party from time to time, including a network; Third
              Party Inputs means third parties or any goods and services
              provided by third parties, including accounting software such as
              Xero, and including end users, suppliers, hosting providers or
              other subcontractors which the provision of the Services may be
              contingent on, or impacted by; and User Data means the
              information, materials, documents, licences, certifications,
              logos, documents, qualifications or data inputted by you, your
              Personnel and Authorised Users into the Services or stored by the
              Services or generated by the Services as a result of your use of
              the Services.
            </ListItem>
            <ListItem>
              Interpretation In these Terms, unless the context otherwise
              requires: (a) a reference to these Terms or any other document
              includes the document, all schedules and all annexures as novated,
              amended, supplemented, varied or replaced from time to time; (b) a
              reference to any legislation or law includes subordinate
              legislation or law and all amendments, consolidations,
              replacements or re-enactments from time to time; (c) a reference
              to a natural person includes a body corporate, partnership, joint
              venture, association, government or statutory body or authority or
              other legal entity and vice versa; (d) no clause will be
              interpreted to the disadvantage of a Party merely because that
              Party drafted the clause or would otherwise benefit from it; (e) a
              reference to a party (including a Party) to a document includes
              that party’s executors, administrators, successors, permitted
              assigns and persons substituted by novation from time to time; (f)
              a reference to a covenant, obligation or agreement of two or more
              persons binds or benefits them jointly and severally; (g) a
              reference to time is to local time in New South Wales; and (h) a
              reference to $ or dollars refers to the currency of Australia from
              time to time.
            </ListItem>
          </OrderedList>
          <Text>
            For any questions and notices, please contact us at: API Software
            Pty Ltd t/a Canyou Software(ABN 57 650 764 195) Email:
            support@canyou.com.au
          </Text>
          <small>Last update: 7 June 2021 </small>
        </VStack>
      </Flex>
    </Layout>
  );
};

export default Terms;
